import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

const Snak = ({ isSnak }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [state, setState] = React.useState('');
    const [errMsg, setErr] = useState('')


    const handleCloseSnak = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    useEffect(() => {
        setOpen(isSnak[0])
        setErr(isSnak[1])
        setState(isSnak[2])
    }, [isSnak])

    return (
        <div className={classes.root}>

            <Snackbar open={open} autoHideDuration={5000} onClose={handleCloseSnak}>
                <Alert onClose={handleCloseSnak} severity={state}>
                    {errMsg}
                </Alert>
            </Snackbar>
            {/* <Alert severity="error">This is an error message!</Alert>
            <Alert severity="warning">This is a warning message!</Alert>
            <Alert severity="info">This is an information message!</Alert>
              <Alert onClose={handleCloseSnak} severity={"error"}>
            <Alert severity="success">This is a success message!</Alert> */}
        </div>
    );
}
export default Snak;
