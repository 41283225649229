import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBYnIj76ErAJON7j_65oa4Z3HCaHWN02hU",
  authDomain: "reactfirebase-972e2.firebaseapp.com",
  projectId: "reactfirebase-972e2",
  storageBucket: "reactfirebase-972e2.appspot.com",
  messagingSenderId: "787593695182",
  appId: "1:787593695182:web:9301c336331f710186659f",
  measurementId: "G-E6799X3560",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging();

export const requestForToken = async () => {
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    try {
      const token = await getToken(messaging, {
        vapidKey:
          "BEJDYQzb6IsTiVQk24w0HBeOOIzlQDkQE5H1TfkasSYlXajyQGk2J-05YQ4Nnk_vkhgmKUd24gxZNgI8R27BnqQ",
      });

      return token;
    } catch (err) {
      console.error("An error occurred while retrieving token :", err);
    }
  }
};
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });
