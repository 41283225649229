import * as Yup from "yup";
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const validationSchema = Yup.object({
  img: Yup.mixed()
    .nullable()
    .required(" image required ")
    .test(
      "FILE_SIZE",
      "uploaded file is too big",
      (value) => !value || (value && value.size <= 2000 * 2000)
    )
    .test(
      "FILE_FORMAT",
      "uploaded file is unsupported format",
      (value) => !value || (value && SUPPORTED_FORMATS.includes(value?.type))
    ),
});
const validationSchemaLogin = Yup.object({
  phoneNumber: Yup.mixed()
    .nullable()
    .required("  Phone Number required ")
    .test(
      "no-only-spaces",
      "Field cannot contain only spaces",
      (value) => !/^\s+$/.test(value)
    ),
  password: Yup.mixed()
    .nullable()
    .required("  Password required ")
    .test(
      "no-only-spaces",
      "Field cannot contain only spaces",
      (value) => !/^\s+$/.test(value)
    ),
});

const validationSchemaProduct = Yup.object({
  name: Yup.mixed().nullable().required(" name required "),
  description: Yup.mixed().nullable().required(" description required "),
  serviceId: Yup.string().required("please select an option"),
  images: Yup.mixed()
    .test(" file", "please choose one at least", (value) => {
      if (value && value?.length === 0) return false;
      return true;
    })
    .test("fileSize", "File Size is too large", (value) => {
      if (value && value?.length > 0) {
        for (let i = 0; i < value.length; i++) {
          if (value[i].size > 5242880) {
            return false;
          }
        }
      }
      return true;
    })
    .test("fileType", "Unsupported File Format", (value) => {
      if (value && value.length > 0) {
        for (let i = 0; i < value.length; i++) {
          if (
            value[i].type !== "image/png" &&
            value[i].type !== "image/jpg" &&
            value[i].type !== "image/jpeg"
          ) {
            return false;
          }
        }
      }
      return true;
    }),
});
export { validationSchema, validationSchemaProduct, validationSchemaLogin };
