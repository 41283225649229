
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useFormik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import { useHistory } from 'react-router-dom';

import { validationSchemaLogin } from "../../util/validationSchema";

import BgImage from "../../assets/img/illustrations/signin.svg";

import Snak from "../../components/feedBack/Snak";

import "../../style/style.css"
//firebase
import { requestForToken } from "../../firebase";



const Login = () => {
    const base_url = 'https://allcare.center/api/v1'
    const history = useHistory();
    const [openSnak, setSnak] = useState([false, '', ''])

    useEffect(() => {
        // التحقق من وجود التوكن في localStorage
        const authToken = localStorage.getItem("token");
        if (authToken) {
            history.push('/reservations');
        }
    }, [history]);


    const formik = useFormik({
        initialValues: { phoneNumber: '', password: '' },
        validationSchema: validationSchemaLogin,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const token = await requestForToken()
            const body = {
                'phone_number': values.phoneNumber,
                'password': values.password,
                'fcm_token': token
            }
            try {
                setSubmitting(true)
                const res = await axios.post(`${base_url}/auth/login`, body, {
                    headers: {
                        'Accept': 'application/json',
                    },
                })
                if (res.data.success && res.data.data.token) {
                    console.log(res);
                    localStorage.setItem('token', res.data.data.token)
                    setSnak([true, res.data.message, 'success'])
                    history.push(`/reservations`)
                }
            } catch (error) {
                console.error(error);
                setSnak([true, error.response.data.message, 'error'])
            } finally {
                setSubmitting(false);
            }
        },
    });


    return (
        <main>
            <Snak isSnak={openSnak} />
            <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
                <Container>
                    <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
                        <Col xs={12} className="d-flex align-items-center justify-content-center">
                            <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 form-hight fmxw-500">
                                <div className="text-center text-md-center mb-4 mt-md-0">
                                    <h3 className="mb-0">All Care</h3>
                                </div>
                                <Form onSubmit={formik.handleSubmit} className="mt-4">
                                    <Form.Group id="email" className="mb-4">
                                        <Form.Label>Phone Number</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Text>
                                                <FontAwesomeIcon icon={faPhone} />
                                            </InputGroup.Text>
                                            <Form.Control name="phoneNumber" value={formik.values.phoneNumber} onChange={formik.handleChange} autoFocus type="text" placeholder="Phone Number" />

                                        </InputGroup>
                                        <Form.Control.Feedback type="invalid " tooltip>
                                            <div className="text-danger fs-8"> {formik.errors.phoneNumber}</div>
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Group id="password" className="pb-5 ">
                                            <Form.Label> Password</Form.Label>
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faUnlockAlt} />
                                                </InputGroup.Text>
                                                <Form.Control name="password" value={formik.values.password} onChange={formik.handleChange} type="password" placeholder="Password" />

                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid " tooltip>
                                                <span className="text-danger fs-8"> {formik.errors.password}</span>
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        {/* <div className="d-flex justify-content-between align-items-center mb-4">
                                            <Form.Check type="checkbox">
                                                <FormCheck.Input id="defaultCheck5" className="me-2" />
                                                <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Remember me</FormCheck.Label>
                                            </Form.Check>
                                        </div> */}
                                    </Form.Group>
                                    <Button variant="primary" type="submit" className="w-100 ">
                                        {formik.isSubmitting ? <div className="load-3 mb-1">

                                            <div className="line"></div>
                                            <div className="line"></div>
                                            <div className="line"></div>
                                        </div> : <span>Sign in</span>}
                                    </Button>
                                </Form>


                                {/* <div className="d-flex justify-content-center my-4">
                                    <Button variant="outline-light" className="btn-icon-only btn-pill text-facebook me-2">
                                        <FontAwesomeIcon icon={faFacebookF} />
                                    </Button>
                                    <Button variant="outline-light" className="btn-icon-only btn-pill text-twitter me-2">
                                        <FontAwesomeIcon icon={faTwitter} />
                                    </Button>
                                    <Button variant="outline-light" className="btn-icon-only btn-pil text-dark">
                                        <FontAwesomeIcon icon={faGithub} />
                                    </Button>
                                </div> */}

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
};
export default Login;
