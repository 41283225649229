import React, { useState, useEffect } from "react";
import styles from "././styles/clock.module.css";
const { clock, date, loader } = styles;
const Clock = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Function to add leading zeros if necessary
  const formatTime = (unit) => {
    return unit < 10 ? `0${unit}` : unit;
  };

  // Function to convert to 12-hour format
  const formatHours = (hours) => {
    return hours % 12 || 12; // Convert 0 to 12 for midnight and handle other cases
  };

  // Extract day and month
  const day = formatTime(time.getDate());
  const month = formatTime(time.getMonth() + 1); // Month is zero-indexed

  const hours = formatHours(time.getHours());
  const minutes = formatTime(time.getMinutes());
  const seconds = formatTime(time.getSeconds());
  const ampm = time.getHours() >= 12 ? "PM" : "AM";

  return (
    <div className={clock}>
      <div style={{ fontSize: "25px", fontFamily: "Arial, sans-serif" }}>
        {hours} : {minutes} : {seconds} {ampm}
      </div>
      <div
        className={date}
        style={{
          fontFamily: "Arial, sans-serif",
        }}
      >
        <div className={loader}></div> {month} / {day}
      </div>
    </div>
  );
};

export default Clock;
