import { Toast, Button } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBootstrap } from '@fortawesome/free-brands-svg-icons';
import { faDna } from '@fortawesome/free-solid-svg-icons';
import './toasts.css'
const Toasts = ({ showToast, handleCloseToast, payload }) => {

    return (
        <Toast show={showToast} onClose={handleCloseToast} className="bg-primary text-white my-3 position">
            <Toast.Header className="text-primary" closeButton={false}>
                <FontAwesomeIcon icon={faDna} />
                <strong className="me-auto ms-2"> {payload.title} </strong>
                {/* <small>11 mins ago</small> */}
                <Button variant="close" size="xs" onClick={handleCloseToast} />
            </Toast.Header>
            <Toast.Body>
                {payload.body}
            </Toast.Body>
        </Toast>
    )
}

export default Toasts