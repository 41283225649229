//library
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
//components
import { Breadcrumb, Col, Row } from "@themesberg/react-bootstrap";
import { Routes } from "../../routes";
import StickyHeadTable from "../../components/tables/StickyHeadTable";

const dataProduct = [];
const dataService = [];

const Doctors = () => {
  const [servicesList, setServicesList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const deleteItem = (id) => {
    console.log(id);
    const newList = dataTable.filter((item) => item.id !== id);
    //call API
    setDataTable(newList);
  };
  const columns = [
    { id: "num", label: "#", minWidth: 50 },
    { id: "name", label: "Product Name", minWidth: 50 },
    { id: "description", label: "Description", minWidth: 50, align: "center" },
    { id: "serviceName", label: "Service Name", minWidth: 50 },
    {
      id: "id",
      label: "Action",
      minWidth: 50,
      align: "center",
      format: (value) => (
        <>
          <button
            className="text-white rounded border border-0 p-2 bg-danger"
            onClick={() => deleteItem(value)}
          >
            Delete
          </button>{" "}
          <button
            className="text-white rounded border border-0 p-2 bg-success"
            onClick={() => console.log(value)}
          >
            Edit{" "}
          </button>
        </>
      ),
    },
  ];
  function createData(num, name, description, id, serviceName) {
    return { num, name, description, id, serviceName };
  }
  const rows = dataTable.map((item, indx) =>
    createData(indx + 1, item.name, item.description, item.id, item.serviceName)
  );
  //add section name from number of section id
  const dataHandler = (dataProduct, dataService) => {
    let finalData = [];
    for (let i = 0; i < dataProduct.length; i++) {
      // itemService it always array have one item
      let itemService = dataService.filter(
        (item) => item.id === dataProduct[i].serviceId
      );

      let obj = { serviceName: itemService[0].name, ...dataProduct[i] };
      finalData.push(obj);
    }
    setDataTable(finalData);
    console.log("final data :", finalData);
  };
  useEffect(() => {
    setServicesList(dataService);
    setProductList(dataProduct);
    //state 200
    dataHandler(productList, servicesList);
  }, [productList, servicesList]);
  return (
    <>
      {" "}
      <Row>
        <Col lg={4}>
          <Breadcrumb
            listProps={{
              className:
                "breadcrumb-secondary breadcrumb-transparent breadcrumb",
            }}
          >
            <Breadcrumb.Item>Doctors</Breadcrumb.Item>
            <Breadcrumb.Item active to={Routes.Doctors.path}>
              {" "}
              Doctors
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <StickyHeadTable columns={columns} rows={rows}>
        <div className="bg-dark text-warning p-2 w-25 rounded text-center">
          Doctors
        </div>
      </StickyHeadTable>
    </>
  );
};
export default Doctors;
