// import { useNavigate } from "react-router-dom"
import './loading.css'
const Loading = ({ children, loadState, error }) => {
    // const navigate = useNavigate();
    if (error) {
        // return navigate('/error')
        console.log(error);
    }
    if (loadState) {
        // return <p className="m-0 p-0" >loading ...</p>
        return (
            <div class="loader"></div>
        )
    }
    return (
        children
    )
}
export default Loading;