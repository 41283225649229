// lib
import React, { useEffect } from "react";
import { useState } from "react";
import { format } from 'date-fns';
import { onMessageListener, requestForToken } from "../../firebase";
//hooks
import useApi from "../../hooks/useApi";
import useToast from "../../hooks/useToast";
// route
import { Routes } from "../../routes"
// com
import Loading from "../../components/feedBack/Loading/Loading";
import Snak from "../../components/feedBack/Snak";
import Toasts from "../../components/feedBack/Toasts/Toasts";
import { Breadcrumb } from "@themesberg/react-bootstrap"
import StickyHeadTable from "../../components/tables/StickyHeadTable";
//styles
import "../../style/style.css"
import axios from "axios";

// this function for dialog
// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const Reservations = () => {
    const base_url = 'https://allcare.center/api/v1'
    const { callApi, loadingState, error } = useApi()
    const { handleCloseToast, handleOpenToast, showToast } = useToast()
    const [openSnak, setSnak] = useState([false, '', ''])
    const [notification, setNotification] = useState({ title: '', body: '' });
    const [reservations, setReservations] = useState([]);

    const getDataOnReload = () => {
        const getReservation = async () => {
            const res = await callApi({
                method: "GET",
                url: `${base_url}/reservation/getAll`,
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            })

            if (res.data?.success) {
                // setReservations((prev) => [...prev, ...res.data.data])
                setReservations([...res.data.data])
            } else {
                setSnak([true, res.message, 'error'])
            }
        }
        getReservation()
    }


    // const deleteItem = (id) => {
    //     console.log(id);
    //     const newList = reservations.filter((item) => item.id !== id);
    //     //call API
    //     setReservations(newList);
    // };
    // const [open, setOpen] = React.useState(false);
    // const [itemWillDeleted, setItemWillDeleted] = useState(null)
    // const handleClickOpen = (value) => {
    //     setItemWillDeleted(value)
    //     setOpen(true);
    // };

    // const handleCloseWithDelete = () => {
    //     deleteItem(itemWillDeleted)
    //     setOpen(false);
    //     setItemWillDeleted(null)
    // };
    // const handleClose = () => {
    //     setOpen(false);
    //     setItemWillDeleted(null)
    // };
    function createData(key, clientName, phone, description, date) {
        return { key, clientName, phone, description, date };
    }
    // const rows = reservations.map((item, index) =>
    //     createData(index, item.doctor.doctor_langs[0].full_name, item.client_name, item.email, item.phone, item.description, item.date, item.time)
    // );
    const rows = reservations.map((item, index) => {
        return createData(index, item.client_name, item.phone, item.description, item.created_at)
    });
    // const columns = [
    //     { id: "doctorName", label: "Doctor Name", minWidth: 50 },
    //     { id: "clientName", label: "Client Name", minWidth: 50 },
    //     { id: "email", label: "Email", minWidth: 50 },
    //     { id: "phone", label: "Phone", minWidth: 50 },
    //     { id: "description", label: "Description", minWidth: 50 },
    //     {
    //         id: "date",
    //         label: "Date",
    //         minWidth: 50,
    //         format: (value) => (

    //             <p className="m-0">
    //                 {format(new Date(value), 'MMMM d, yyyy')}
    //             </p>

    //         ),
    //     },
    //     { id: "time", label: "Time", minWidth: 50 },
    // ];
    const columns = [
        { id: "clientName", label: "Client Name", minWidth: 50, align: "center" },
        { id: "phone", label: "Phone", minWidth: 50, align: "center" },
        { id: "description", label: "Description", minWidth: 50, align: "center" },
        {
            id: "date",
            label: "Date",
            minWidth: 50,
            align: "center",
            format: (value) => (

                <p className="m-0">
                    {format(new Date(value), 'MM/dd/yyyy  ,  hh:mm')}
                </p>

            ),
        },
    ];

    useEffect(() => {
        const getReservation = async () => {
            const res = await callApi({
                method: "GET",
                url: `${base_url}/reservation/getAll`,
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            })

            if (res.data?.success) {
                // setReservations((prev) => [...prev, ...res.data.data])
                setReservations([...res.data.data])
            } else {
                setSnak([true, res.message, 'error'])
            }
        }
        getReservation()
    }, [callApi])
    useEffect(() => {
        requestForToken();
        const getReservationAfterNotfication = async () => {
            try {
                const res = await axios.get(`${base_url}/reservation/getAll`, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    }
                },)
                if (res.data?.success) {
                    setReservations([...res.data.data])
                } else {
                    setSnak([true, res.message, 'error'])
                }
            } catch (err) {
                console.log(err);

                setSnak([true, err.message, 'error'])
            }
        }
        onMessageListener().then((payload) => {
            setNotification({
                title: payload?.notification?.title,
                body: payload?.notification?.body
            });
            handleOpenToast()
            getReservationAfterNotfication()
        })

    }, [notification, handleOpenToast])

    return (
        <>
            {/* <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Are you sure you want delete this ?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleCloseWithDelete} color="primary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog> */}
            <Snak isSnak={openSnak} />
            <Toasts handleCloseToast={handleCloseToast} showToast={showToast} payload={notification} />
            <Breadcrumb listProps={{ className: "breadcrumb-secondary breadcrumb-transparent breadcrumb mt-5" }}>

                <Breadcrumb.Item active to={Routes.Reservations.path} className=" text-black"> Reservations </Breadcrumb.Item>

            </Breadcrumb>

            <StickyHeadTable columns={columns} rows={rows} getDataOnReload={getDataOnReload}>

                <div className="bg-dark text-white p-2 pe-3 ps-3 rounded text-center">
                    <Loading loadState={loadingState} error={error}>
                        Reservations
                    </Loading>
                </div>
            </StickyHeadTable >
        </>
    )
}

export default Reservations