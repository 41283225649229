import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { Routes } from "../routes";
// pages

import { Doctors, AddDoctor } from "./doctor";
// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Preloader from "../components/Preloader";
import Modals from "./components/Modals";
import Toasts from "./components/Toasts";
import ErrorPage from "./error/ErrorPage";
import Reservations from "./Reservations/Reservations";
import Login from "./auth/Login";

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Preloader show={loaded ? false : true} /> <Component {...props} />{" "}
        </>
      )}
    />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  // const history = useHistory();
  // const [loaded, setLoaded] = useState(false);
  // useEffect(() => {
  //   const timer = setTimeout(() => setLoaded(true), 1000);
  //   return () => clearTimeout(timer);
  // }, []);

  // const localStorageIsSettingsVisible = () => {
  //   return localStorage.getItem("settingsVisible") === "false" ? false : true;
  // };

  // const [showSettings, setShowSettings] = useState(
  //   localStorageIsSettingsVisible
  // );

  // const toggleSettings = () => {
  //   setShowSettings(!showSettings);
  //   localStorage.setItem("settingsVisible", !showSettings);
  // };

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {/* <Preloader show={loaded ? false : true} /> */}
          <Sidebar />

          <main className="content">
            <Navbar />
            <Component {...props} />
            {/* <Footer
              toggleSettings={toggleSettings}
              showSettings={showSettings}
            /> */}
          </main>
        </>
      )}
    />
  );
};
const isAuthenticated = () => {
  // You can replace this with actual authentication logic
  return !!localStorage.getItem("token"); // Assume a token is stored in localStorage when authenticated
};
const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to={Routes.Login.path} />
        )
      }
    />
  );
};
const HomePage = () => {
  return (
    <Switch>
      <RouteWithLoader exact path={Routes.Login.path} component={Login} />
      <ProtectedRoute
        exact
        path={Routes.AddDoctors.path}
        component={(props) => (
          <RouteWithSidebar {...props} component={AddDoctor} />
        )}
      />
      <ProtectedRoute
        exact
        path={Routes.Doctors.path}
        component={(props) => (
          <RouteWithSidebar {...props} component={Doctors} />
        )}
      />

      <ProtectedRoute
        exact
        path={Routes.Reservations.path}
        component={(props) => (
          <RouteWithSidebar {...props} component={Reservations} />
        )}
      />

      <ProtectedRoute
        exact
        path={Routes.Modals.path}
        component={(props) => (
          <RouteWithSidebar {...props} component={Modals} />
        )}
      />
      <ProtectedRoute
        exact
        path={Routes.Toasts.path}
        component={(props) => (
          <RouteWithSidebar {...props} component={Toasts} />
        )}
      />

      <RouteWithLoader exact path={Routes.Error.path} component={ErrorPage} />
      <Redirect to={Routes.Error.path} />
      {/* توجيه الخطأ */}
      {/* <RouteWithLoader exact path={Routes.Error.path} component={ErrorPage} /> */}
      {/* التوجيه الافتراضي للمسارات غير الصحيحة */}
      {/* <Route path="*">
        <Redirect to={Routes.Error.path} />
      </Route> */}
    </Switch>
  );
};
export default HomePage;
