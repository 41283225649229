//library
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
//components
import { Breadcrumb, Card, Col, Form, Row } from "@themesberg/react-bootstrap";
import { Routes } from "../../routes";
import { Button } from "@themesberg/react-bootstrap";
import SelectedListFormat from "../../components/SelectedListFormat";
//util
import { validationSchemaProduct } from "../../util/validationSchema";

const dataService = [
  {
    id: 1,
    name: "web dev",
    description: "aaaaaaaaaaaaaaaaaaaaaaaa",
    sId: 1,
  },
  {
    id: 2,
    name: "mobile dev",
    description: "aaaaaaaaaaaaaaaaaaaaaaaa",
    sId: 2,
  },
  {
    id: 3,
    name: "design mobile app",
    description: "aaaaaaaaaaaaaaaaaaaaaaaa",
    sId: 2,
  },
];

const AddDoctor = () => {
  const [productList, setProductList] = useState([]);
  const handleImageChange = (e) => {
    formik.setFieldValue("images", e.currentTarget.files);
  };
  const handleFormData = (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("description", values.description);
    formData.append("serviceId", values.serviceId);
    for (let i = 0; i < values.images.length; i++) {
      formData.append("images[]", values.images[i]);
    }
    return formData;
  };
  const formik = useFormik({
    initialValues: {
      name: null,
      description: null,
      serviceId: "",
      images: [],
    },
    validationSchema: validationSchemaProduct,
    onSubmit: async (values, { setSubmitting }) => {
      const dataFormatted = handleFormData(values);
      // log each image in form data to make sure
      dataFormatted.forEach((value, key) => {
        if (key === "images") {
          console.log("Images:", Array.from(value));
        } else {
          console.log(`${key}:`, value);
        }
      });
      try {
        //call API
      } catch (error) {
        console.log(error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    setProductList(dataService);
  }, []);
  return (
    <>
      {" "}
      <Row>
        <Col lg={4}>
          <Breadcrumb
            listProps={{
              className:
                "breadcrumb-secondary breadcrumb-transparent breadcrumb",
            }}
          >
            <Breadcrumb.Item>Doctors</Breadcrumb.Item>
            <Breadcrumb.Item active to={Routes.AddDoctors.path}>
              {" "}
              Add Doctors
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Card border="light" className="mt-2 bg-white shadow-sm mb-4">
        <Card.Body>
          <h5 className="mb-4">Add Doctors</h5>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group>
                  <Row>
                    <Col md={6}>
                      <Form.Label>Service</Form.Label>
                      <Form.Select
                        isInvalid={
                          formik.errors.serviceId && formik.touched.serviceId
                        }
                        name="serviceId"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.serviceId}
                        className="form-select"
                      >
                        <option value="" label="Select an service" />
                        <SelectedListFormat options={productList} />
                      </Form.Select>
                      {formik.touched.serviceId && formik.errors.serviceId && (
                        <div className="text-danger">
                          {formik.errors.serviceId}
                        </div>
                      )}
                    </Col>
                    <Col md={6}>
                      <Form.Label>Choose Photos</Form.Label>
                      <Form.Control
                        isInvalid={
                          formik.touched.images && formik.errors.images
                        }
                        name="images"
                        multiple
                        onChange={handleImageChange}
                        type="file"
                        className="w-100"
                      />
                      {formik.touched.images && formik.errors.images && (
                        <div className="text-danger">
                          {formik.errors.images}
                        </div>
                      )}
                    </Col>
                  </Row>

                  <Form.Label className="mt-3"> Name</Form.Label>
                  <Form.Control
                    isInvalid={formik.errors.name && formik.touched.name}
                    name="name"
                    onChange={formik.handleChange}
                    type="text"
                    className="w-100 "
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className="text-danger">{formik.errors.name}</div>
                  )}
                  <Form.Label className="mt-3"> Description</Form.Label>
                  <Form.Control
                    isInvalid={
                      formik.errors.description && formik.touched.description
                    }
                    name="description"
                    onChange={formik.handleChange}
                    as="textarea"
                    rows="3"
                    className="w-100"
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className="text-danger">
                      {formik.errors.description}
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <div className="mt-3">
              <Button
                disabled={formik.isSubmitting}
                variant="primary"
                type="submit"
              >
                Add New Product
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
export default AddDoctor;
