import React from "react";
import Clock from "./Clock";
import {
  Nav,
  Image,
  Navbar,
  Container,
  Dropdown,
} from "@themesberg/react-bootstrap";

import KulAlriayaLogo from "../assets/img/technologies/Kul_Alriaya_logo_2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faEnvelopeOpen,
  faSignOutAlt,
  faUserCircle,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

export default (props) => {
  // const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);
  // const areNotificationsRead = notifications.reduce(
  //   (acc, notif) => acc && notif.read,
  //   true
  // );

  // const markNotificationsAsRead = () => {
  //   setTimeout(() => {
  //     setNotifications(notifications.map((n) => ({ ...n, read: true })));
  //   }, 300);
  // };

  // const Notification = (props) => {
  //   const { link, sender, image, time, message, read = false } = props;
  //   const readClassName = read ? "" : "text-danger";

  //   return (
  //     <ListGroup.Item action href={link} className="border-bottom border-light">
  //       <Row className="align-items-center">
  //         <Col className="col-auto">
  //           <Image
  //             src={image}
  //             className="user-avatar lg-avatar rounded-circle"
  //           />
  //         </Col>
  //         <Col className="ps-0 ms--2">
  //           <div className="d-flex justify-content-between align-items-center">
  //             <div>
  //               <h4 className="h6 mb-0 text-small">{sender}</h4>
  //             </div>
  //             <div className="text-end">
  //               <small className={readClassName}>{time}</small>
  //             </div>
  //           </div>
  //           <p className="font-small mt-1 mb-0">{message}</p>
  //         </Col>
  //       </Row>
  //     </ListGroup.Item>
  //   );
  // };
  const history = useHistory();
  const signOut = () => {
    localStorage.removeItem("token");
    console.log("gg");

    history.push("/");
  };
  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex  w-100">
          <Nav className="align-items-center w-100">
            {/* bell */}
            {/* <Dropdown as={Nav.Item} onToggle={markNotificationsAsRead}>
              <Dropdown.Toggle
                as={Nav.Link}
                className="text-dark icon-notifications me-lg-3"
              >
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faBell} className="bell-shake" />
                  {areNotificationsRead ? null : (
                    <span className="icon-badge rounded-circle unread-notifications" />
                  )}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                <ListGroup className="list-group-flush">
                  <Nav.Link
                    href="#"
                    className="text-center text-primary fw-bold border-bottom border-light py-3"
                  >
                    Notifications
                  </Nav.Link>

                  {notifications.map((n) => (
                    <Notification key={`notification-${n.id}`} {...n} />
                  ))}

                  <Dropdown.Item className="text-center text-primary fw-bold py-3">
                    View all
                  </Dropdown.Item>
                </ListGroup>
              </Dropdown.Menu>
            </Dropdown> */}
            <div className=" d-flex align-items-center  w-100">
              <div className=" text-dark align-items-center d-none d-lg-block w-100">
                <span className="mb-0 font-small fw-bold">
                  <Clock />
                </span>
              </div>
              {/* <Image
                src={KulAlriayaLogo}
                className="user-avatar md-avatar rounded-circle"
                height="100"
                style={{ width: "auto" }}
              /> */}
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle as={Nav.Link} className="pt-1 ">
                  <div className=" d-flex align-items-center">
                    <Image
                      src={KulAlriayaLogo}
                      className="user-avatar md-avatar rounded-circle"
                      height="100"
                      style={{ width: "auto" }}
                    />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                  <Dropdown.Item onClick={signOut} className="fw-bold">
                    <FontAwesomeIcon
                      icon={faSignOutAlt}
                      className="text-danger me-2"
                    />{" "}
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};
