import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  root: {
    padding: "10px 40px 0 40px",
    width: "100%",
    marginBottom: "25px",
    // direction: "rtl",
  },
  container: {
    maxHeight: "auto",
    // direction: "rtl",
  },
  customScrollbar: {
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "5px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
});

export { useStyles };
