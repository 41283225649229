import axios from "axios";
/*
we use callback here for when we have setState
in useEffect in main file it make infintin loop because callApi method will take new number in memory every time the state make
re-render in main file
*/
import { useCallback, useState } from "react";

const useApi = () => {
  const [loadingState, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const callApi = useCallback(async (data) => {
    try {
      setLoading(true); //if the value of state does not change that wont make new re-rendre
      setError(false);
      const response = await axios(data);
      return response;
    } catch (err) {
      setError(true);
      console.log("error from useApi:", err);
      return err;
    } finally {
      setLoading(false);
    }
  }, []);

  return { callApi, loadingState, error };
};

export default useApi;
